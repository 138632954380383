import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';

import { Button, SimpleDialog, SimpleInput } from 'Components/Shared/UI';

import { Cookie } from 'utils/handlers';
import { saveToStorage } from 'utils/persist';
import detectDeviceByUserAgent from 'utils/detectDeviceByUserAgent';

import { useStyles } from './styles';

const PreferencesDialog = ({
  open,
  onClose,
  labels: defaultLabels,
  seekLabels: defaultSeekLabels,
}) => {
  const t = useTranslations();
  const classes = useStyles();

  const [labels, setLabels] = useState([]);
  const [seekLabels, setSeekLabels] = useState([]);

  const deviceType =
    typeof window !== 'undefined'
      ? detectDeviceByUserAgent(window.navigator.userAgent)
      : 'desktop';

  useEffect(() => {
    if (!labels.length) {
      setLabels(defaultLabels);
    }
    if (!seekLabels.length) {
      setSeekLabels(defaultSeekLabels);
    }
  }, [defaultLabels, defaultSeekLabels, labels.length, seekLabels.length]);

  const handleButtonClick = async () => {
    const filteredLabels = labels.filter(({ value }) => value);
    const filteredSeekLabels = seekLabels.filter(({ value }) => value);

    const isSet = Cookie.setCookieByKey(
      'sugar_preferences',
      {
        labels: filteredLabels,
        seekLabels: filteredSeekLabels,
      },
      365,
    );

    if (!isSet) {
      saveToStorage(
        'sugar_preferences',
        {
          labels: filteredLabels,
          seekLabels: filteredSeekLabels,
        },
        'sessionStorage',
      );
    }

    onClose();
  };

  return (
    <SimpleDialog
      title=" "
      open={open}
      withCloseButton
      onClose={onClose}
      className={classes.dialog}
      fullScreen={deviceType === 'mobile'}
    >
      <div className={classes.container}>
        <img
          src="/images/sexjobs-logo.svg"
          alt="sexjobs-logo"
          className={classes.logo}
        />
        <div className={classes.description}>
          {t('preferences_dialog_description')}
        </div>
        <div className={classes.columnsWrapper}>
          <div className={classes.column}>
            <h3 className={classes.heading}>
              {t('preferences_dialog_label_title')}
            </h3>
            {labels.map(({ label, id }) => (
              <SimpleInput
                key={id}
                type="radio"
                name="labelList"
                label={{ title: t(label), color: 'white', forId: id }}
                value={label}
                onChange={() =>
                  setLabels(prev =>
                    prev.map(item => ({ ...item, value: item.id === id })),
                  )
                }
                className={classes.input}
              />
            ))}
          </div>
          <div className={classes.column}>
            <h3 className={classes.heading}>
              {t('preferences_dialog_filter_title')}
            </h3>
            {seekLabels?.map(({ label, id, value }) => (
              <SimpleInput
                key={id}
                type="checkbox"
                value={value}
                label={{ title: t(label), color: 'white', forId: id }}
                onChange={e => {
                  setSeekLabels(prev =>
                    prev.map(item =>
                      item.id === id
                        ? {
                            ...item,
                            value: e.target.checked,
                            label: !label.includes('seek')
                              ? `seek_${label}`
                              : label,
                          }
                        : item,
                    ),
                  );
                }}
                className={classes.input}
              />
            ))}
          </div>
        </div>
        <Button
          fullWidth
          mobileFullWidth
          typeButton="default"
          className={classes.button}
          onClick={handleButtonClick}
          text={t('preferences_dialog_button_text')}
          disabled={
            labels.every(({ value }) => !value) ||
            seekLabels.every(({ value }) => !value)
          }
        />
      </div>
    </SimpleDialog>
  );
};

export default PreferencesDialog;
